import { Injectable, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class UtilsHandle {
  readonly activatedRoute = inject(ActivatedRoute);

  redirectToReturnUrl(): void {
    const returnUrl = this.getReturnUrl();
    if (returnUrl) {
      window.open(returnUrl, '_self');
    }
  }

  private getReturnUrl(): string {
    return this.getReturnUrlFromQueryParams() || this.getReturnUrlFromLocalStorage();
  }

  private getReturnUrlFromQueryParams(): string {
    return this.activatedRoute.snapshot.queryParams['backUrl'] || '';
  }

  private getReturnUrlFromLocalStorage(): string {
    return localStorage.getItem('returnUrl') || '';
  }
}
